export default class Subnav {

    constructor() {
        getBody().addEventListener("click", Subnav.hide);
    }

    static hide() {
       document.getElementById('subnav__toggleId').checked = false;
    }
}


function getBody() {
    return document.querySelector(".cola-main")
}