export default class Appdrawer {

    constructor() {
        appdrawerElement().addEventListener('click', Appdrawer.close);
        this.registerKeyListener();
        document.getElementById('cola-header__nav-item-apps').addEventListener('click', Appdrawer.open);
    }

    registerKeyListener() {
        document.addEventListener('keyup', event => {
            const metaKeyCodes = [91, 92, 224];
            const key = event.which;
            if (event.altKey && metaKeyCodes.includes(key)) {
                Appdrawer.toggle();
            }
        });
    }

    static open() {
        appdrawerElement().style.transform = 'none';
        appdrawerElement().classList.add('is-showing');
        document.documentElement.style.overflow = 'hidden';
    }

    static close() {
        appdrawerElement().style.transform = 'translateY(-100%)';
        appdrawerElement().classList.remove('is-showing');
        document.documentElement.style.overflow = 'auto';
    }

    static toggle() {
        const appdrawerIsOpen = appdrawerElement().classList.contains('is-showing');
        if (appdrawerIsOpen) Appdrawer.close();
        else Appdrawer.open();
    }
}

function appdrawerElement() {
    return document.getElementById('cola-appdrawer');
}
